import { useEffect } from "react";
import styled from "styled-components";
import BlockManager from "../../../shared/blocks/BlockManager";
import {
  getBlogPostsByCategory,
  getPageBlocks,
  getTestimonials,
} from "../../../shared/lib/api";
import { ActiveCampaignForm } from "../components/global/ActivecampaignForm";
import Layout from "../components/global/Layout";
import Seo from "../components/global/Seo";
import { BUCKET_URL } from "../model/S3Bucket";
import { CompanyOperation } from "../redux/companiesSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { fetchJobsAsync, selectJobs } from "../redux/jobsSlice";
import TalentpoolLogo from "/public/svg/Logo.svg";

const ContainerBGLight = styled.div`
  background: #f5f5f7;
`;

const Home = ({ allPosts: { edges }, blocks, testimonials, preview }) => {
  const dispatch = useAppDispatch();
  const allJobs = useAppSelector(selectJobs);
  const title =
    "easyboarding | Job in der Touristik finden und Karriere starten";
  const description =
    "easyboarding ist dein Karriere-Boost für die Touristik. Wir bieten Recruiting-Events, einen Talentpool für die Touristik und liefern nützliche Karrieretipps.";

  const fetchData = async (token, operation) => {
    const nowISODate = new Date().toISOString();
    const filter = {};
    filter["expiresAt"] = { gte: nowISODate };

    const variables = {
      nextToken: token,
      operation: operation,
      filter: filter,
    };
    await dispatch(fetchJobsAsync(variables));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (allJobs == null) {
      fetchData(null, CompanyOperation.INITIAL);
    }
  }, [dispatch, allJobs]);

  const getTopJobs = () =>
    allJobs != null ? allJobs?.filter((job) => job.isHighlight) : [];

  return (
    <>
      <Seo title={title} description={description} noSuffix />
      <Layout preview={preview}>
        <ContainerBGLight>
          <BlockManager
            blocks={blocks}
            blogPosts={edges}
            highlightJobs={getTopJobs()}
            LogoSvg={TalentpoolLogo}
            bucketUrl={BUCKET_URL}
            acForm={<ActiveCampaignForm />}
            testimonialsCompanies={testimonials}
          />
        </ContainerBGLight>
      </Layout>
    </>
  );
};

export default Home;

export async function getStaticProps({ preview = false, previewData }) {
  const allPosts = await getBlogPostsByCategory(preview, "Featured");
  const blocks = await getPageBlocks("landingpage", preview, previewData);
  const testimonials = await getTestimonials();

  return {
    props: { allPosts, blocks, testimonials, preview },
    revalidate: 60,
  };
}
