import { useFormik } from 'formik';
import { useState } from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import AlertManager from '../../../../shared/components/global/AlertManager';
import { Button, ButtonSizes } from '../../../../shared/styles/button';
import { device } from "../../../../shared/styles/device";
import { Input, InputTextbox } from '../../../../shared/styles/input';
import { FormError } from '../../../../shared/styles/validation';

const InputRowDual = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
  margin-bottom: 1rem;
  grid-row-gap: 1rem;
  
  @media ${device.tablet} {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-column-gap: 1rem;
  }
`;

const InputRowSingle = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
  margin-bottom: 1rem;
`;
const ACForm = styled.form`
    max-width: 650px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
    
    @media ${device.tablet} {
        margin-top: 64px;
    }
    label {
        text-align: left;
    }
`;

export const ActiveCampaignForm = () => {
    const [loading, setLoading] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)

    const formik = useFormik({
        initialValues: {
            lastname: '',
            firstname: '',
            email: '',
        },
        validationSchema: Yup.object({
            lastname: Yup.string()
                .required('Pflichtfeld.'),
            firstname: Yup.string()
                .required('Pflichtfeld.'),
            email: Yup.string()
                .email()
                .required('Pflichtfeld.'),
        }),

        onSubmit: (values: any) => {
            setLoading(true)

            let formData: FormData = new FormData();

            for (var key in values) {
                let acKey = key;
                formData.append(acKey, values[key]);
            }

            formData.append("u", "2");
            formData.append("f", "2");
            formData.append("s", "");
            formData.append("c", "0");
            formData.append("m", "0");
            formData.append("act", "sub");
            formData.append("v", "2");
            formData.append("or", "718de8cd5f1f1398febe662641c05cde");
            formData.append("jsonp", "true");

            fetch("https://easyboarding.activehosted.com/proc.php", {
                method: "POST",
                body: formData,
                mode: "no-cors",
            })
                .then(() => {
                    setLoading(false);
                    setFormSubmitted(true);
                    setTimeout(() => {
                        setFormSubmitted(false);
                    }, 5000);
                    formik.resetForm();
                    Swal.fire(AlertManager.ActiveCampaignMessageSuccessNewsletter());
                })
                .catch(error => {
                    setLoading(false);
                    Swal.fire(AlertManager.ActiveCampaignMessageError(error, formData));
                    console.error("Request failed", error);
                });
        },
    });

    return (
        <div id="contact">
            {/*                 <h2>Jetzt unverbindlich anfragen</h2>
                <h3>Optimiere dein Recruiting über die Bewerberakquise beim STELL-MICH-EIN-Talentpool:</h3> */}
            <ACForm onSubmit={formik.handleSubmit}>
                <InputRowDual>
                    <InputTextbox>
                        <Input
                            id="firstname"
                            className={`${formik.values.firstname?.length != null && formik.values.firstname?.length > 0 ? "form-textbox-entered " : ""} form-textbox-input`}
                            name="firstname"
                            type="text"
                            value={formik.values.firstname}
                            error={formik.touched.firstname && formik.errors.firstname != null}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <span className='form-textbox-label'>Vorname</span>
                    </InputTextbox>
                    <InputTextbox>
                        <Input
                            id="lastname"
                            className={`${formik.values.lastname?.length != null && formik.values.lastname?.length > 0 ? "form-textbox-entered " : ""} form-textbox-input`}
                            name="lastname"
                            type="text"
                            value={formik.values.lastname}
                            error={formik.touched.lastname && formik.errors.lastname != null}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <span className='form-textbox-label'>Nachname</span>
                    </InputTextbox>
                </InputRowDual>
                <InputRowSingle>
                    <InputTextbox>
                        <Input
                            id="email"
                            className={`${formik.values.email?.length != null && formik.values.email?.length > 0 ? "form-textbox-entered " : ""} form-textbox-input`}
                            name="email"
                            type="text"
                            value={formik.values.email}
                            error={formik.touched.email && formik.errors.email != null}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <span className='form-textbox-label'>E-Mail Adresse</span>
                    </InputTextbox>
                    {formik.touched.email && formik.errors.email ?
                        <div className='form-message-wrapper'>
                            <FormError errorText={formik.errors.email} />
                        </div>
                        : null}
                </InputRowSingle>
                <InputRowSingle>
                    <Button size={ButtonSizes.m} disabled={loading || !formik.isValid} $loading={loading} primary type="submit">
                        Newsletter abonnieren
                    </Button>
                </InputRowSingle>
            </ACForm>
        </div>
    );

}
